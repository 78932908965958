import vladimir from 'assets/images/vladimir.jpg';
import igor from 'assets/images/igor.jpg';

export const LOCALES = {
	UA: 'uk',
	EN: 'en',
};

export const CONTENT = {
	[LOCALES.UA]: {
		header: {
			menu: [
				'Послуги', 'Про нас', 'Команда', 'Контакти',
			],
		},
		main: {
			heading: `Записатися на прийом`,
			description: `Ми пишаємося тим,
			 що пропонуємо високу якість обслуговування, 
			 яке починається з бронювання за попереднім записом. 
			 Наша онлайн-система дозволяє вам вибрати майстра і зручний для вас час.`,
			button: 'Запис',
		},
		warning: {
			heading: 'Увага',
			description: 'У звʼязку з карантинними обмеженнями приймаємо відвідувачів за наявності сертифікату про вакцинацію або негативного ПЛР тесту.',
			button: 'Зрозуміло',
		},
		services: {
			heading: 'Послуги',
			beard: [
				{
					name: 'Борода',
					price: 300,
				},
				{
					name: 'Гоління обличчя',
					price: 300,
				},
			],
			haircut: [
				{
					name: 'Стрижка',
					price: 500,
				},
				{
					name: 'Стрижка машинкою',
					price: 400,
				},
				{
					name: 'Дитяча стрижка',
					price: 450,
				},
				{
					name: 'Укладка',
					price: 200,
				},
			],
			complex: [
				{
					name: 'Стрижка & Борода',
					price: 700,
				},
				{
					name: 'Машинка & Борода',
					price: 600,
				},
				{
					name: 'Батько & Син',
					price: 850,
				},
			],
		},
		about: {
			heading: 'ЛАСКАВО ПРОСИМО ДО БАРБЕРШОП SKILL',
			description: `Це чоловіча перукарня, відкрита майстрами, які знають і якісно роблять свою справу.
			
			Наші клієнти з нами вже дуже давно. Приходьте і відчуйте легкість в спілкуванні, професіоналізм та розуміння барберської справи у майстрів. Якщо потрібна якісна послуга, то вам саме до нас!`,
		},
		team: {
			heading: 'Команда',
			list: [
				{
					img: igor,
					name: 'Ігор Удовенко',
				},
				{
					img: vladimir,
					name: 'Володимир Ющенко',
				}
			],
		},
		contacts: {
			heading: 'Контакти',
			infoList: [
				{
					title: 'АДРЕСА:',
					description: 'вул. Михайла Драгоманова 2Б'
				},
				{
					title: 'ТЕЛЕФОН:',
					description: '+38063 951 59 41'
				},
				{
					title: 'ГОДИНИ РОБОТИ:',
					description: '10:00 - 20:00'
				}
			],
		}
	},
	[LOCALES.EN]: {
		header: {
			menu: [
				'Services', 'About us', 'Team', 'Contact',
			],
		},
		main: {
			heading: `Book an appointment`,
			description: `We pride ourselves in offering a high quality
			 of service and that begins with appointment based bookings.
			  Our online system allows you to pick a stylist and time that’s
			   convenient for you.`,
			button: `Book now`,
		},
		warning: {
			heading: 'Attention',
			description: 'Due to quarantine restrictions, we accept visitors with a vaccine certificate or a negative PCR test.',
			button: 'Clearly',
		},
		services: {
			heading: 'Services',
			beard: [
				{
					name: 'Beard',
					price: 250,
				},
				{
					name: 'Face shaving',
					price: 250,
				},
			],
			haircut: [
				{
					name: 'Haircut',
					price: 500,
				},
				{
					name: 'Haircut with a clipper',
					price: 400,
				},
				{
					name: 'Children\'s haircut',
					price: 400,
				},
				{
					name: 'Styling',
					price: 150,
				},
			],
			complex: [
				{
					name: 'Haircut & Beard',
					price: 650,
				},
				{
					name: 'Clipper & Beard',
					price: 550,
				},
				{
					name: 'Father & Son',
					price: 800,
				},
			],
		},
		about: {
			heading: 'WELCOME TO BARBERSHOP SKILL',
			description: `This is a men's hairdressing salon, opened by masters who know and do their job with high quality.
			
				Our clients have been with us for a very long time. Come and feel the ease of communication, professionalism and understanding of the barber business from the masters. If you need a high-quality service, then you should come to us!`,
		},
		team: {
			heading: 'Team',
			list: [
				{
					img: igor,
					name: 'Igor Udovenko',
				},
				{
					img: vladimir,
					name: 'Volodymyr Yushchenko',
				}
			],
		},
		contacts: {
			heading: 'Contacts',
			infoList: [
				{
					title: 'ADDRESS:',
					description: 'Mykhaila Drahomanova str. 2B'
				},
				{
					title: 'PHONE:',
					description: '+38063 951 59 41'
				},
				{
					title: 'OPENING HOURS:',
					description: '10:00 - 20:00'
				}
			],
		}
	},
};
